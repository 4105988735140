import {
  FetchPaginatedStatementsArgs,
  FetchPaginatedStatementsResponse,
} from './fetch-paginated-statements.types';
import { fetchPaginatedStatements } from './fetch-paginated-statements';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';

export const useGetPaginatedStatements = <
  TransformedData = FetchPaginatedStatementsResponse,
>(
  props?: GenericFetchProps<
    FetchPaginatedStatementsArgs,
    FetchPaginatedStatementsResponse,
    TransformedData
  >,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch payouts records.',
    queryObj: getPaginatedPayoutsQueryObj,
    ...props,
  });
};

export const getPaginatedPayoutsQueryObj = getGenericQueryObj({
  groupName: 'statements-paginated-records',
  query: fetchPaginatedStatements,
});

export const useInvalidateGetPaginatedStatementsCache = () => {
  const invalidateGetPaginatedStatementsCache = useGetInvalidateQueryCache(
    getPaginatedPayoutsQueryObj,
  );

  return { invalidateGetPaginatedStatementsCache };
};
