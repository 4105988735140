import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import {
  FetchPaginatedMachinesArgs,
  FetchPaginatedMachinesResponse,
} from './fetch-paginated-machines.types';

const fetchPaginatedMachines = (args: FetchPaginatedMachinesArgs) => {
  const {
    attentionOnly = false,
    count,
    difficulty = [],
    locations = [],
    machineIssues = [],
    materials,
    page,
    search = '',
    sortingField = null,
    sortingType = null,
    statuses = [],
    types = [],
  } = args;

  return axios
    .post<FetchPaginatedMachinesResponse>(
      `${coreApiUrl}/machines/paginatedMachines`,
      {
        attentionOnly,
        count,
        difficulty,
        locations,
        machineIssues,
        materials,
        page,
        search,
        sortingField,
        sortingType,
        statuses,
        types,
      },
    )
    .then(({ data }) => {
      return data;
    });
};

export const fetchPaginatedMachinesQueryObj = getGenericQueryObj({
  groupName: 'fetch-paginated-machines',
  query: fetchPaginatedMachines,
});
