export const statusBadgeThemes = {
  //   blueDark: 'blue_dark',
  blueLight: 'blue_light',
  greenDark: 'green_dark',
  greenLight: 'green_light',
  //   monochromeDark: 'monochrome_dark',
  monochromeLight: 'monochrome_light',
  //   redDark: 'red_dark',
  redLight: 'red_light',
  //   yellowDark: 'yellow_dark',
  yellowLight: 'yellow_light',
} as const;
