import styled, { css } from 'styled-components';
import { Typography } from 'gantri-components';

export const StyledStateBox = styled.div<{
  $active?: boolean;
}>`
  ${({ $active, theme }) => {
    return css`
      padding: ${theme.dimensions.spacing.s1};
      background: rgba(241, 245, 254, 1);
      box-shadow: 0px 2px 4px 0px rgba(51, 93, 201, 0.3);
      border-radius: ${theme.dimensions.spacing['.5x']};
      border: 1px solid
        ${$active ? theme.colors.palette.blue_400 : 'transparent'};
    `;
  }}
`;

export const StyledTypography = styled(Typography)`
  ${({ theme }) => {
    return css`
      span {
        color: ${theme.colors.typography.t2};
      }
    `;
  }}
`;
