import { v4 as uuidv4 } from 'uuid';
import { InventoryRequest } from '../../../../../../../../../../../../../../api/jobs/jobs.types';
import {
  spoolInventoryNeedValues,
  spoolInventoryOpaqueIds,
} from '../../new-spool-inventory.constants';
import { Inventory } from '../../../../../../../../../../../../../../api/inventories/routes/fetch-all/fetch-all-inventories.types';

export const getInventoryRequestsForInventory = (inventory: Inventory) => {
  if (inventory) {
    const { id, name, status, stock, type, unit } = inventory;

    const inventoryRequests: InventoryRequest[] = [
      {
        discarded: 0,
        id: uuidv4(),
        inventory: {
          id,
          name,
          status,
          stock: Number(stock || null),
          type,
          unit,
        },
        inventoryId: id,
        need: spoolInventoryOpaqueIds.includes(id)
          ? spoolInventoryNeedValues.opaque
          : spoolInventoryNeedValues.translucent,
      },
    ];

    return inventoryRequests;
  }

  return [];
};
