import { FC, memo, useMemo } from 'react';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { Flex, Stack, Table, Typography } from 'gantri-components';
import { upperFirst } from 'lodash';
import routePaths from '../../../config/route-paths';
import { formatDate } from '../../../helpers/formatter';
import { StatementTransaction } from '../../../api/statements/routes';
import { StyledAnchor } from '../../../components/common/styled-anchor';
import { getIsClickableIfValue } from '../../../helpers/get-is-clickable-if-value';
import { formatStatementCurrency } from '../../statements/helpers/format-statement-currency';
import { Label } from '../../../components/label';

/** @example '10095-lg-sedona-white_rod_finish' => '10095-Lg-Sedona-White_Rod_Finish' */
export const formatSkuForStatement = (sku: string) => {
  const skuSeparator = '-';
  const variantSeparator = '_';

  return sku
    .split(skuSeparator)
    .map((value) => {
      return value
        .split(variantSeparator)
        .map((detail) => {
          return upperFirst(detail);
        })
        .join(variantSeparator);
    })
    .join(skuSeparator);
};

const useGetStatementItemsColumns = (data: StatementTransaction[]) => {
  const { grandTotal, totalAmount } = useMemo(() => {
    return data.reduce(
      (accumulator, item) => {
        return {
          grandTotal:
            accumulator.grandTotal + item.quantity * item.pricePerItem,
          totalAmount: accumulator.totalAmount + item.pricePerItem,
        };
      },
      {
        grandTotal: 0,
        totalAmount: 0,
      },
    );
  }, [data]);

  const columns: ColumnDef<StatementTransaction>[] = useMemo(() => {
    return [
      {
        accessorKey: 'date',
        cell: ({
          getValue,
        }: CellContext<StatementTransaction, StatementTransaction['date']>) => {
          return <Typography text={formatDate(getValue())} />;
        },
        footer: 'TOTAL',
        header: 'Date',
        size: 80,
      },
      {
        accessorKey: 'order',
        cell: ({
          getValue,
        }: CellContext<
          StatementTransaction,
          StatementTransaction['order']
        >) => {
          const order = getValue();

          return (
            <Flex gap="x">
              <Typography display="inline" text={order.type} />
              <StyledAnchor
                display="inline"
                text={`#${order.id}`}
                to={`${routePaths.orders}/${order.id}`}
              />
            </Flex>
          );
        },
        header: 'Order',
        meta: {
          getIsClickable: getIsClickableIfValue,
        },
      },
      {
        accessorKey: 'sku',
        cell: ({
          getValue,
        }: CellContext<StatementTransaction, StatementTransaction['sku']>) => {
          const sku = getValue();

          return <Typography text={formatSkuForStatement(sku)} />;
        },
        header: 'SKU',
      },
      {
        accessorKey: 'quantity',
        cell: ({
          getValue,
          row,
        }: CellContext<
          StatementTransaction,
          StatementTransaction['quantity']
        >) => {
          const quantity = getValue();
          const { pricePerItem: amount } = row.original;
          const quantityValue = amount < 0 ? `(${quantity})` : quantity;

          return <Typography align="right" text={quantityValue} />;
        },
        header: () => {
          return <Label align="right" text="Quantity" />;
        },
        size: 80,
      },
      {
        accessorKey: 'pricePerItem',
        cell: ({
          getValue,
        }: CellContext<
          StatementTransaction,
          StatementTransaction['pricePerItem']
        >) => {
          const pricePerItem = getValue();

          return (
            <Typography
              align="right"
              text={formatStatementCurrency(pricePerItem)}
            />
          );
        },
        header: () => {
          return <Label align="right" text="Price per item" />;
        },
        size: 80,
      },
      {
        accessorKey: 'total',
        cell: ({
          getValue,
        }: CellContext<
          StatementTransaction,
          StatementTransaction['pricePerItem']
        >) => {
          const total = getValue();

          return (
            <Typography
              align="right"
              color="t2"
              text={formatStatementCurrency(total)}
            />
          );
        },
        footer: () => {
          return (
            <Typography
              align="right"
              text={formatStatementCurrency(grandTotal)}
              textStyle="bold"
            />
          );
        },
        header: () => {
          return <Label align="right" text="Total" />;
        },
        id: 'total',
        size: 80,
      },
    ];
  }, [grandTotal, totalAmount]);

  return columns;
};

export const StatementItems: FC<{ items: StatementTransaction[] }> = memo(
  ({ items }) => {
    const columns = useGetStatementItemsColumns(items);

    return (
      <Stack width="100%">
        <Typography text="Items" textStyle="bold" variant="h4" />
        <Table columns={columns} data={items} highlightHoveredRow />
      </Stack>
    );
  },
);
