import styled, { css } from 'styled-components';

export const StyledAvatarWrapper = styled.div`
  ${({ theme }) => {
    return css`
      aspect-ratio: 1;
      padding: 0.4rem;
      background-color: ${theme.colors.surfaces.monochrome.t1Alt};
      border: 1px solid ${theme.colors.dividers.t1};
    `;
  }}
`;

export const StyledAvatar = styled.div<{ img: string }>`
  ${({ img }) => {
    return css`
      height: 100%;
      width: 100%;
      background-image: url(${img});
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    `;
  }}
`;
