import * as yup from 'yup';
import { statementStatuses } from '../../../api/statements/routes';
import { StatementFormSchemaDef } from './statement-form.types';

export const statementFormSchema: yup.SchemaOf<StatementFormSchemaDef> = yup
  .object()
  .shape({
    email: yup.string().nullable(),
    method: yup
      .string()
      .when('status', {
        is: (value) => {
          return value === statementStatuses.paid;
        },
        then: (schema) => {
          return schema.required('Change to Paid requires a method.');
        },
      })
      .nullable(),
    name: yup.string().nullable(),
    notes: yup.string().nullable(),
    status: yup.mixed().oneOf(Object.values(statementStatuses)).required(),
  });
