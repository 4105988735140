import { addMonths, setDate } from 'date-fns';
import {
  StatementsSortingField,
  StatementCategory,
  StatementStatus,
  statementStatuses,
} from '../../api/statements/routes';
import { DateRangeFilter } from '../../components/common/table/components/table-filters/table-filters.types';
import { getSortByFilterAtomFamily } from '../../components/common/table/hooks/use-table-filters/use-table-filters.atoms';
import { getGenericAtomFamily } from '../../helpers/get-generic-atom-family';
import { SortBy } from '../../components/common/table/hooks/use-table-filters/use-table-filters.types';
import { AtomPageName } from '../../helpers/get-generic-atom-family/get-generic-atom-family.types';
import { formatDate } from '../../helpers/formatter';
import { API_DATE_FORMAT } from '../../constants/dates';
import { TransactionType } from '../../constants/options';

const toDate = setDate(new Date(), 1);

export const payoutsFiltersDefaults: {
  filters: {
    categories: StatementCategory[];
    dateRange: DateRangeFilter;
    designerId: number;
    statuses: StatementStatus[];
    types: TransactionType[];
  };
  sortBy: SortBy<StatementsSortingField>;
} = {
  filters: {
    categories: [],
    dateRange: {
      from: formatDate(addMonths(toDate, -4), API_DATE_FORMAT),
      to: formatDate(toDate, API_DATE_FORMAT),
    },
    designerId: undefined,
    statuses: [
      statementStatuses.paid,
      statementStatuses.processed,
      statementStatuses.unpaid,
    ],
    types: [],
  },
  sortBy: {
    sortingField: 'status',
    sortingType: 'DESC',
  },
};

const atomKeyPrefix = 'PAYOUTS_FILTERS';

export const pageName: AtomPageName = 'payouts';

export const payoutsPageAtoms = {
  defaults: payoutsFiltersDefaults,
  filters: {
    categories: getGenericAtomFamily({
      defaultValue: payoutsFiltersDefaults.filters.categories,
      key: `${atomKeyPrefix}-categories`,
    })(pageName),
    dateRange: getGenericAtomFamily({
      defaultValue: payoutsFiltersDefaults.filters.dateRange,
      key: `${atomKeyPrefix}-date-range`,
    })(pageName),
    designerId: getGenericAtomFamily({
      defaultValue: payoutsFiltersDefaults.filters.designerId,
      key: `${atomKeyPrefix}-designer-id`,
    })(pageName),
    statuses: getGenericAtomFamily({
      defaultValue: payoutsFiltersDefaults.filters.statuses,
      key: `${atomKeyPrefix}-statuses`,
    })(pageName),
    types: getGenericAtomFamily({
      defaultValue: payoutsFiltersDefaults.filters.types,
      key: `${atomKeyPrefix}-types`,
    })(pageName),
  },
  sortBy: getSortByFilterAtomFamily<SortBy<StatementsSortingField>>({
    defaultValue: payoutsFiltersDefaults.sortBy,
  })(pageName),
};
