import routePaths from '../../../config/route-paths';
import { primaryStepsForDashboard } from '../../../constants/dashboards';
import { MenuItem } from './sidebar-links.types';

const adminItems: MenuItem[] = [
  {
    icon: 'view:magnifying_glass_filled',
    name: 'Search Part',
    path: routePaths.searchPart,
    type: 'link',
  },
  {
    activeSubPaths: [routePaths.dashboard],
    icon: 'ui-control:grid',
    name: 'Dashboard',
    path: routePaths.main,
    subPaths: primaryStepsForDashboard.map((dash) => {
      return {
        name: `${dash}`,
        path: `${routePaths.dashboard}/${dash.toLowerCase()}`,
        type: 'link',
      };
    }),
    type: 'link',
  },
  {
    icon: 'payment:shopping_bag',
    name: 'Orders',
    path: routePaths.orders,
    type: 'link',
  },
  { type: 'spacer' },
  {
    activeSubPaths: [routePaths.productReviews],
    icon: 'people:person',
    name: 'Users',
    path: routePaths.users,
    subPaths: [
      {
        name: 'Product Reviews',
        path: routePaths.productReviews,
        type: 'link',
      },
    ],
    type: 'link',
  },
  {
    activeSubPaths: [routePaths.invites],
    icon: 'machine:laptop',
    name: 'Designers',
    path: routePaths.designers,
    subPaths: [
      {
        name: 'Invites',
        path: routePaths.invites,
        type: 'link',
      },
    ],
    type: 'link',
  },
  {
    icon: 'people:people',
    name: 'Referrals',
    path: routePaths.referrals,
    type: 'link',
  },
  {
    icon: 'payment:gift_box',
    name: 'Gift Codes',
    path: routePaths.giftCodes,
    type: 'link',
  },
  {
    icon: 'payment:gift_card',
    name: 'Gift Cards',
    path: routePaths.giftCards,
    type: 'link',
  },
  { type: 'spacer' },
  {
    activeSubPaths: [routePaths.stockSummary],
    icon: 'ui-control:lines_three',
    name: 'Jobs',
    path: routePaths.jobs,
    type: 'link',
  },
  {
    activeSubPaths: [routePaths.stockSummary],
    icon: 'work:box',
    name: 'Stocks',
    path: routePaths.stocks,
    subPaths: [
      {
        name: 'Stock Summary',
        path: routePaths.stockSummary,
        type: 'link',
      },
    ],
    type: 'link',
  },
  {
    icon: 'work:wrench',
    name: 'Parts',
    path: routePaths.parts,
    type: 'link',
  },
  {
    icon: 'ui-control:lines_two_dots',
    name: 'Batches',
    path: routePaths.batches,
    type: 'link',
  },
  {
    icon: 'location:location_pin',
    name: 'Locations',
    path: routePaths.locations,
    type: 'link',
  },
  {
    icon: 'work:print_nozzle',
    name: 'Machines',
    path: routePaths.machines,
    type: 'link',
  },
  {
    activeSubPaths: [
      routePaths.newInventoryPurchase,
      routePaths.inventoryPurchases,
    ],
    icon: 'work:basket_filled',
    name: 'Inventories',
    path: routePaths.inventories,
    subPaths: [
      {
        name: 'Vendors',
        path: routePaths.vendors,
        type: 'link',
      },
      {
        name: 'Inventory Purchases',
        path: routePaths.inventoryPurchases,
        type: 'link',
      },
    ],
    type: 'link',
  },
  { type: 'spacer' },
  {
    icon: 'docs:pencil',
    name: 'Designs',
    path: routePaths.designs,
    type: 'link',
  },
  {
    icon: 'work:light_bulb',
    name: 'Products',
    path: routePaths.products,
    type: 'link',
  },
  {
    activeSubPaths: [routePaths.transactions],
    icon: 'payment:cash',
    name: 'Statements',
    path: routePaths.statements,
    subPaths: [
      {
        name: 'Transactions',
        path: routePaths.transactions,
        type: 'link',
      },
    ],
    type: 'link',
  },
  {
    icon: 'arrows:arrow_line_down',
    name: 'Downloads',
    path: routePaths.downloads,
    type: 'link',
  },
  {
    icon: 'actions:link',
    name: 'Press',
    path: routePaths.press,
    type: 'link',
  },
];

const workerItems: MenuItem[] = [
  {
    icon: 'view:magnifying_glass_filled',
    name: 'Search Part',
    path: routePaths.searchPart,
    type: 'link',
  },
  {
    activeSubPaths: [routePaths.stockSummary],
    icon: 'ui-control:lines_three',
    name: 'Jobs',
    path: routePaths.jobs,
    type: 'link',
  },
  {
    activeSubPaths: [routePaths.stockSummary],
    icon: 'work:box',
    name: 'Stocks',
    path: routePaths.stocks,
    subPaths: [
      {
        name: 'Stock Summary',
        path: routePaths.stockSummary,
        type: 'link',
      },
    ],
    type: 'link',
  },
  {
    icon: 'work:wrench',
    name: 'Parts',
    path: routePaths.parts,
    type: 'link',
  },
  {
    icon: 'work:print_nozzle',
    name: 'Machines',
    path: routePaths.machines,
    type: 'link',
  },
];

const leadItems: MenuItem[] = [
  {
    icon: 'view:magnifying_glass_filled',
    name: 'Search Part',
    path: routePaths.searchPart,
    type: 'link',
  },
  {
    icon: 'payment:shopping_bag',
    name: 'Orders',
    path: routePaths.orders,
    type: 'link',
  },
  { type: 'spacer' },
  {
    activeSubPaths: [routePaths.stockSummary],
    icon: 'ui-control:lines_three',
    name: 'Jobs',
    path: routePaths.jobs,
    type: 'link',
  },
  {
    activeSubPaths: [routePaths.stockSummary],
    icon: 'work:box',
    name: 'Stocks',
    path: routePaths.stocks,
    subPaths: [
      {
        name: 'Stock Summary',
        path: routePaths.stockSummary,
        type: 'link',
      },
    ],
    type: 'link',
  },
  {
    icon: 'work:wrench',
    name: 'Parts',
    path: routePaths.parts,
    type: 'link',
  },
  {
    icon: 'ui-control:lines_two_dots',
    name: 'Batches',
    path: routePaths.batches,
    type: 'link',
  },
  {
    icon: 'location:location_pin',
    name: 'Locations',
    path: routePaths.locations,
    type: 'link',
  },
  {
    icon: 'work:print_nozzle',
    name: 'Machines',
    path: routePaths.machines,
    type: 'link',
  },
  {
    activeSubPaths: [
      routePaths.newInventoryPurchase,
      routePaths.inventoryPurchases,
    ],
    icon: 'work:basket_filled',
    name: 'Inventories',
    path: routePaths.inventories,
    subPaths: [
      {
        name: 'Inventory Purchases',
        path: routePaths.inventoryPurchases,
        type: 'link',
      },
    ],
    type: 'link',
  },
  { type: 'spacer' },
  {
    icon: 'work:light_bulb',
    name: 'Products',
    path: routePaths.products,
    type: 'link',
  },
];

export const menuItems = {
  admin: adminItems,
  lead: leadItems,
  worker: workerItems,
};
