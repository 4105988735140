import { useEffect, useMemo, useState } from 'react';
import { Table, Typography } from 'gantri-components';
import { CellContext } from '@tanstack/react-table';
import routePaths from '../../config/route-paths';
import { PageWithTable } from '../../components/layout';
import { StatementsFilter } from './components/statements-filter';
import {
  FetchPaginatedStatementsArgs,
  PaginatedStatement,
  StatementsSortingField,
  useGetPaginatedStatements,
} from '../../api/statements/routes';
import { useTableFilters } from '../../components/common/table/hooks/use-table-filters';
import {
  pageName,
  payoutsFiltersDefaults,
  payoutsPageAtoms,
} from './statements.atoms';
import { StyledAnchor } from '../../components/common/styled-anchor';
import { getIsClickableIfValue } from '../../helpers/get-is-clickable-if-value';
import { StatementStatusBadge } from './components/statement-status-badge';
import { formatStatementCurrency } from './helpers/format-statement-currency';

const useStatementsColumns = () => {
  const columns = useMemo(() => {
    return [
      {
        accessorKey: 'id',
        cell: ({
          getValue,
        }: CellContext<PaginatedStatement, PaginatedStatement['id']>) => {
          const id = getValue();

          return (
            <StyledAnchor
              href={`${routePaths.statements}/${id}`}
              text={`#${id}`}
            />
          );
        },
        header: 'Statement #',
        maxSize: 100,
        meta: {
          getIsClickable: getIsClickableIfValue,
        },
      },
      {
        accessorKey: 'category',
        header: 'Category',
      },
      {
        accessorKey: 'type',
        header: 'Type',
      },
      {
        accessorKey: 'period',
        header: 'Period',
      },
      {
        accessorKey: 'designer',
        cell: ({
          getValue,
        }: CellContext<PaginatedStatement, PaginatedStatement['designer']>) => {
          const designer = getValue();

          return (
            <StyledAnchor
              text={designer.name}
              to={`${routePaths.designers}/${designer.id}`}
            />
          );
        },
        header: 'Designer',
        meta: {
          getIsClickable: getIsClickableIfValue,
        },
      },
      {
        accessorKey: 'amount',
        cell: ({
          getValue,
        }: CellContext<PaginatedStatement, PaginatedStatement['amount']>) => {
          const amount = getValue();

          return <Typography text={formatStatementCurrency(amount)} />;
        },
        header: 'Amount',
        maxSize: 60,
      },
      {
        accessorKey: 'status',
        cell: ({
          getValue,
        }: CellContext<PaginatedStatement, PaginatedStatement['status']>) => {
          const status = getValue();

          return <StatementStatusBadge status={status} />;
        },
        header: 'Status',
        maxSize: 60,
      },
    ];
  }, []);

  return columns;
};

export const Statements = () => {
  const [filters, updateFilters] = useState<FetchPaginatedStatementsArgs>();

  const statementsColumns = useStatementsColumns();

  const { data, isLoading } = useGetPaginatedStatements({
    enabled: !!filters,
    fetchArgs: filters,
  });

  const {
    filtersProps,
    pagingProps,
    records,
    setRecords,
    setTotalCount,
    sortProps,
  } = useTableFilters<
    PaginatedStatement,
    FetchPaginatedStatementsArgs,
    StatementsSortingField
  >({
    fetchRequest: updateFilters,
    filtersContent: <StatementsFilter />,
    modalFiltersDetails: [
      {
        atom: payoutsPageAtoms.filters.dateRange,
        defaultValue: payoutsFiltersDefaults.filters.dateRange,
        payloadKey: 'dateRange',
      },
      {
        atom: payoutsPageAtoms.filters.statuses,
        defaultValue: payoutsFiltersDefaults.filters.statuses,
        payloadKey: 'statuses',
      },
      {
        atom: payoutsPageAtoms.filters.designerId,
        defaultValue: payoutsFiltersDefaults.filters.designerId,
        payloadKey: 'designerId',
      },
      {
        atom: payoutsPageAtoms.filters.categories,
        defaultValue: payoutsFiltersDefaults.filters.categories,
        payloadKey: 'categories',
      },
      {
        atom: payoutsPageAtoms.filters.types,
        defaultValue: payoutsFiltersDefaults.filters.types,
        payloadKey: 'types',
      },
    ],
    pageName,
    pageSize: 100,
    sortByFilterDetails: {
      atom: payoutsPageAtoms.sortBy,
      defaultValue: payoutsFiltersDefaults.sortBy,
    },
    sortOptions: [
      { bidirectional: true, label: 'Amount', sortingField: 'amount' },
      { bidirectional: true, label: 'Status', sortingField: 'status' },
    ],
  });

  useEffect(() => {
    if (!isLoading) {
      setRecords(data?.data || []);
      setTotalCount(data?.pagination?.totalItems || 0);
    }
  }, [isLoading, data]);

  const pageTitle = 'Statements';

  return (
    <PageWithTable pageTitle={pageTitle}>
      <PageWithTable.Header title={pageTitle} />
      <PageWithTable.Content>
        <Table
          columns={statementsColumns}
          data={records}
          filters={filtersProps}
          highlightHoveredRow
          paging={pagingProps}
          sorting={sortProps}
        />
      </PageWithTable.Content>
    </PageWithTable>
  );
};
