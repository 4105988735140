/* eslint-disable   sort-keys-fix/sort-keys-fix */

import { PartialRecord } from '../types/partial-record';

export const materials = {
  fabbOpaque: 'ColorFabb HT Opaque',
  polymakerOpaque: 'Polymaker Sandable Opaque',
  fabbTranslucent: 'ColorFabb GPLA Translucent',
  polymakerTranslucent: 'Polymaker Translucent',
  na: 'N/A',
} as const;

export type Material = typeof materials[keyof typeof materials];

export const isTranslucentMaterial = (type: Material) => {
  return (
    type === materials.fabbTranslucent ||
    type === materials.polymakerTranslucent
  );
};

export const isOpaqueMaterial = (type: Material) => {
  return type === materials.polymakerOpaque || type === materials.fabbOpaque;
};

export const materialsFilterOptions = Object.values(materials).filter((op) => {
  return op !== materials.na;
});

type InventoryTransaction = { id: string; name: string; weight: string };

export const inventoryTransactionSpoolDetails: PartialRecord<
  Material,
  InventoryTransaction
> = {
  [materials.fabbOpaque]: {
    id: '#10913',
    name: 'Material - High-T (1.75 mm) 4.5 Spool.',
    weight: '4.5',
  },
  [materials.polymakerOpaque]: {
    id: '#10912',
    name: 'Material - High-T (1.75 mm) Sandable 4.5.',
    weight: '4.5',
  },
  [materials.fabbTranslucent]: {
    id: '#10178',
    name: 'Material - Gantri PLA (1.75 mm).',
    weight: '5',
  },
  [materials.polymakerTranslucent]: {
    id: '#10966',
    name: 'Material - Polymaker STR PLA (1.75mm).',
    weight: '4.5',
  },
};

export const getMaterialsByOpacity = (material: Material): Material[] => {
  if (isOpaqueMaterial(material)) {
    return [materials.fabbOpaque, materials.polymakerOpaque];
  }

  return [materials.polymakerTranslucent, materials.fabbTranslucent];
};
