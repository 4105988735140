const routePaths = {
  batchPartCreation: '/batch-part-creation',
  batches: '/batches',
  dashboard: '/dashboard',
  design: '/designs/:designId',
  designers: '/designers',
  designs: '/designs',
  downloads: '/downloads',
  giftCards: '/gift-cards',
  giftCodes: '/gift-codes',
  inventories: '/inventories',
  inventoryPurchases: '/inventory-purchases',
  invites: '/invites',
  jobs: '/jobs',
  locations: '/locations',
  machines: '/machines',
  main: '/',
  newInventoryPurchase: '/inventory-purchases/new',
  newOrder: '/new-order',
  newStock: '/new-stock',
  orders: '/orders',
  partReleaseRate: '/part-release-rate',
  parts: '/parts',
  press: '/press',
  productReviews: '/product-reviews',
  products: '/products',
  referrals: '/referrals',
  searchPart: '/search-part',
  statements: '/statements',
  stockSummary: '/stock-summary',
  stocks: '/stocks',
  /** Statement transactions. */
  transactions: '/transactions',
  unauthorized: '/unauthorized',
  users: '/users',
  vendors: '/vendors',
} satisfies Record<string, `/${string}`>;

export const orderActionTypes = {
  reassignStock: 'reassign-stock',
  refund: 'refund',
  replacement: 'replacement',
  return: 'return',
} as const;

export default routePaths;
