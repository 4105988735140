import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import {
  UpdateStatementArgsDef,
  UpdateStatementResponseDef,
} from './update-statement.types';
import { updateStatement } from './update-statement';

export const useUpdateStatement = <
  TransformedData = UpdateStatementResponseDef,
>(
  props?: GenericMutateQueryProps<
    UpdateStatementArgsDef,
    UpdateStatementResponseDef,
    TransformedData
  >,
) => {
  const { onMutate: onUpdateStatement, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to update statement.',
    mutationFn: updateStatement,
    ...props,
  });

  return { ...rest, onUpdateStatement };
};
