import {
  Material,
  materials,
} from '../../../../../../../../../../../../constants/parts';

export const spoolInventoryNeedValues = {
  opaque: 4.5,
  translucent: 5,
} as const;

export const spoolInventoryOpaqueIds = [10912, 10913];

export const spoolInventoryTraslucentIds = [10966, 10178];

export const spoolInventoryDetails = {
  opaqueOldFormulation: {
    description: 'ColorFabb HT Opaque',
    id: 10913,
    material: materials.fabbOpaque,
    need: spoolInventoryNeedValues.opaque,
  },
  opaqueSandable: {
    description: 'Polymaker Sandable Opaque',
    id: 10912,
    material: materials.polymakerOpaque,
    need: spoolInventoryNeedValues.opaque,
  },
  translucentOldFormulation: {
    description: 'ColorFabb GPLA Translucent',
    id: 10178,
    material: materials.fabbTranslucent,
    need: spoolInventoryNeedValues.translucent,
  },
  translucentPolymaker: {
    description: 'Polymaker Translucent',
    id: 10966,
    material: materials.polymakerTranslucent,
    need: spoolInventoryNeedValues.translucent,
  },
} as const satisfies Record<
  string,
  {
    description: string;
    id: number;
    material?: Material;
    need: number;
  }
>;
