import {
  Cell,
  Conditional,
  FormikInput,
  Grid,
  Stack,
  TextField,
  Typography,
} from 'gantri-components';
import { PartJobBlocksInstructions } from '../part-job-blocks-instructions';
import { formatAsLocaleNumber } from '../../../../../../../../../../helpers/formatter';
import {
  defaultJobBlockDurations,
  detailsGridColumns,
  jobsBlockGridColumns,
} from '../../part-job-blocks.constants';
import { PartJobBlocksFinishBlockProps } from './part-job-blocks-finish-block.types';
import { isOpaqueMaterial } from '../../../../../../../../../../constants/parts';
import { Divider } from '../../../../../../../../../../components/divider';
import { useIsEditingPartRowJobsDataForm } from '../../../../hooks/use-is-editing-part-row';
import { partPaintedStatuses } from '../../../../../../../../../../api/products/products.constants';

export const PartJobBlocksFinishBlock = (
  props: PartJobBlocksFinishBlockProps,
) => {
  const { finishBlock, material, paintedStatus } = props;

  const isEditingPartRow = useIsEditingPartRowJobsDataForm();

  const showFinishBlock =
    isOpaqueMaterial(material) &&
    paintedStatus !== partPaintedStatuses.unpainted;

  const formattedSandRawDuration = finishBlock.sandRawDuration
    ? `${formatAsLocaleNumber(finishBlock.sandRawDuration)}m`
    : '-';
  const formattedSandPrimeDuration = finishBlock.sandPrimedDuration
    ? `${formatAsLocaleNumber(finishBlock.sandPrimedDuration)}m`
    : '-';

  return (
    <Conditional condition={showFinishBlock}>
      <Stack gap="4x">
        <Divider />

        <Grid columns={jobsBlockGridColumns} gap="x">
          <Cell>
            <Typography text="Finish" variant="h5" />
          </Cell>
          <Grid columns={detailsGridColumns} gap="x">
            <Typography text="Sand-Raw Duration" textStyle="bold" />
            <Conditional
              condition={isEditingPartRow}
              Fallback={<Typography text={formattedSandRawDuration} />}
            >
              <FormikInput
                Field={
                  <TextField
                    placeholder={`Suggested: ${defaultJobBlockDurations.sandRaw}`}
                    rightIcon={<Typography color="t2" text="m" />}
                    type="number"
                  />
                }
                fieldVariant="standard"
                name="finishBlock.sandRawDuration"
                required
              />
            </Conditional>

            <Typography text="Sand-Primed Duration" textStyle="bold" />
            <Conditional
              condition={isEditingPartRow}
              Fallback={<Typography text={formattedSandPrimeDuration} />}
            >
              <FormikInput
                Field={
                  <TextField
                    placeholder={`Suggested: ${defaultJobBlockDurations.sandPrimed}`}
                    rightIcon={<Typography color="t2" text="m" />}
                    type="number"
                  />
                }
                fieldVariant="standard"
                name="finishBlock.sandPrimedDuration"
                required
              />
            </Conditional>

            <Typography text="Instructions" textStyle="bold" />
            <PartJobBlocksInstructions
              instructions={finishBlock.instructions}
              instructionsJobMap={finishBlock.instructionsJobMap}
              isEditing={isEditingPartRow}
              schemaId="finishBlock.instructions"
            />
          </Grid>
        </Grid>
      </Stack>
    </Conditional>
  );
};
