import { useEffect, useState } from 'react';
import {
  Button,
  Conditional,
  Modal,
  Stack,
  Typography,
} from 'gantri-components';
import { useRecoilState } from 'recoil';
import {
  orderStatusesMap,
  shipmentStatuses,
  stockStatuses,
} from '../../../../constants/options';
import { CancelItemsModalPropsDef } from './cancel-items-modal.types';
import { ShortProductSummary } from '../../../../components/common/short-product-summary';
import { Label } from '../../../../components/label';
import { cancelItemsModalSteps } from './cancel-items-modal.constants';
import { cancelItemsModalAtoms } from './cancel-items-modal.atoms';
import {
  useCancelOrRefundItems,
  useInvalidateFetchOrderCache,
  useInvalidateFetchOrderTimelineCache,
} from '../../../../api/transactions/routes';
import { StyledStockBox } from './cancel-items-modal.styles';

export const CancelItemsModal = (props: CancelItemsModalPropsDef) => {
  const { currentOrderData, onClose } = props;

  const { id: orderId, shipments } = currentOrderData;

  const [shipStocksSelection, setShipStocksSelection] = useState({});
  const [confirmDisabled, setConfirmDisabled] = useState(true);

  const [step, setStep] = useRecoilState(cancelItemsModalAtoms.step);

  const { invalidateFetchOrderCache } = useInvalidateFetchOrderCache();

  const { invalidateFetchOrderTimelineCache } =
    useInvalidateFetchOrderTimelineCache();

  const { isLoading: processing, onCancelOrRefundItems } =
    useCancelOrRefundItems({
      onSuccess: async () => {
        await invalidateFetchOrderCache();
        await invalidateFetchOrderTimelineCache();

        onClose();
      },
    });

  const submitConfirmation = async () => {
    const selectedStockInfoIds = Object.keys(shipStocksSelection)
      .filter((stockInfoId) => {
        return shipStocksSelection[stockInfoId] === true;
      })
      .map((i) => {
        return parseInt(i, 10);
      });
    const refunds = [];

    shipments.forEach((shipment) => {
      return shipment.stocks.forEach((stock) => {
        if (selectedStockInfoIds.includes(stock.stockInfoId)) {
          refunds.push({
            id: stock.stockInfoId,
          });
        }
      });
    });

    await onCancelOrRefundItems({
      orderId,
      refundItems: refunds,
      status: orderStatusesMap.cancelled,
    });
  };

  const totalShipments = shipments && shipments.length;

  const Shipments = () => {
    return (
      <>
        {shipments.map(
          (
            { id: shipmentId, status: shipmentStatus, stocks: shipStocks },
            i,
          ) => {
            const shipmentIdx = i + 1;

            const ShipmentStocks = () => {
              return (
                <>
                  {shipStocks.map(
                    ({ product, status: stockStatus, stockInfoId }) => {
                      const stockSelected = shipStocksSelection[stockInfoId];

                      const handleStockClick = () => {
                        setShipStocksSelection({
                          ...shipStocksSelection,
                          [`${stockInfoId}`]: !stockSelected,
                        });
                      };

                      const hideStockFromModal =
                        stockStatus === stockStatuses.cancelled ||
                        stockStatus === stockStatuses.refunded;

                      return hideStockFromModal ? null : (
                        <StyledStockBox
                          key={stockInfoId}
                          $isSelected={stockSelected}
                          padding="x"
                          role="button"
                          tabIndex={0}
                          onClick={handleStockClick}
                        >
                          <ShortProductSummary fetchSku={product.sku} />
                        </StyledStockBox>
                      );
                    },
                  )}
                </>
              );
            };

            return (
              <Conditional
                key={shipmentId}
                condition={
                  !!shipStocks.length &&
                  [
                    shipmentStatuses.waiting,
                    shipmentStatuses.inProgress,
                    shipmentStatuses.readyToShip,
                  ].some((status) => {
                    return status === shipmentStatus;
                  })
                }
              >
                <Label text={`Shipment ${shipmentIdx}/${totalShipments}`} />
                <ShipmentStocks />
              </Conditional>
            );
          },
        )}
      </>
    );
  };

  useEffect(() => {
    const shipmentStockInfoIdsMap = {};

    if (shipments.length) {
      shipments.forEach(({ stocks: shipStocks }) => {
        shipStocks.forEach(({ stockInfoId }) => {
          shipmentStockInfoIdsMap[stockInfoId] = false;
        });
      });
    }

    setShipStocksSelection(shipmentStockInfoIdsMap);
  }, []);

  useEffect(() => {
    let hasSelectedStock = false;

    Object.keys(shipStocksSelection).forEach((key) => {
      if (shipStocksSelection[key]) {
        hasSelectedStock = true;
      }
    });

    setConfirmDisabled(!hasSelectedStock);
  }, [shipStocksSelection]);

  return (
    <Modal
      footer={
        step === cancelItemsModalSteps.selectItems ? (
          <>
            <Button
              size="large"
              text="Cancel"
              variant="secondary"
              onClick={async () => {
                onClose();
              }}
            />
            <Button
              disabled={confirmDisabled}
              size="large"
              text="Confirm"
              onClick={async () => {
                setStep(cancelItemsModalSteps.confirmCancel);
              }}
            />
          </>
        ) : (
          <>
            <Button
              size="large"
              text="Cancel"
              variant="secondary"
              onClick={async () => {
                setStep(cancelItemsModalSteps.selectItems);
              }}
            />
            <Button
              processing={processing}
              size="large"
              text="Confirm"
              onClick={async () => {
                await submitConfirmation();
              }}
            />
          </>
        )
      }
      header="Cancel Items"
      maxWidth={{ lg: '40rem', md: '100%' }}
      onClose={onClose}
    >
      {step === cancelItemsModalSteps.selectItems ? (
        <Shipments />
      ) : (
        <Stack alignContent="center" minHeight="10rem">
          <Typography
            align="center"
            text="Are you sure you want to cancel these items?"
          />
        </Stack>
      )}
    </Modal>
  );
};
