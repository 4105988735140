import { useEffect, useMemo, useState } from 'react';
import { Flex, Table, Typography } from 'gantri-components';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import routePaths from '../../config/route-paths';
import { PageWithTable } from '../../components/layout';
import { TransactionsFilters } from './components/transactions-filters';
import {
  FetchPaginatedStatementTransactionsArgsDef,
  PaginatedStatementTransactionDef,
  TransactionsSortingField,
  useGetPaginatedStatementTransactions,
  fetchPaginatedStatementTransactions,
} from '../../api/statements/routes';
import { useTableFilters } from '../../components/common/table/hooks/use-table-filters';
import {
  pageName,
  transactionsFiltersDefaults,
  transactionsPageAtoms,
} from './transactions.atoms';
import { StyledAnchor } from '../../components/common/styled-anchor';
import { getIsClickableIfValue } from '../../helpers/get-is-clickable-if-value';
import { formatStatementCurrency } from '../statements/helpers/format-statement-currency';
import { formatDate } from '../../helpers/formatter';
import { formatSkuForStatement } from '../statement/statement-items';
import { useGetDownloadMenuOption } from '../../hooks/use-get-download-menu-option';
import Protected from '../../components/common/protected/protected';
import { useMoreMenu } from '../../hooks/use-more-menu';
import { formatAsLinkForCSV } from '../../hooks/use-get-download-menu-option/helpers/format-as-link-for-csv';
import { DownloadColumn } from '../../hooks/use-get-download-menu-option/helpers/download-csv/download-csv.types';
import { getAdminUrl } from '../../helpers/get-admin-url';
import { formatStatementQuantity } from '../statements/helpers/format-statement-quantity';
import { userRoles } from '../../constants/users';

const useTransactionsColumns = () => {
  const columns = useMemo(() => {
    const columns: ColumnDef<PaginatedStatementTransactionDef>[] = [
      {
        accessorKey: 'date',
        cell: ({
          getValue,
        }: CellContext<
          PaginatedStatementTransactionDef,
          PaginatedStatementTransactionDef['date']
        >) => {
          const date = getValue();

          return <Typography text={formatDate(date)} />;
        },
        header: 'Date',
        size: 80,
      },
      {
        accessorKey: 'designerName',
        cell: ({
          getValue,
        }: CellContext<
          PaginatedStatementTransactionDef,
          PaginatedStatementTransactionDef['designerName']
        >) => {
          const designer = getValue();

          return <Typography text={designer} />;
        },
        header: 'Designer',
      },
      {
        accessorKey: 'category',
        header: 'Category',
        size: 80,
      },
      {
        accessorKey: 'type',
        header: 'Type',
        size: 80,
      },
      {
        accessorKey: 'order',
        cell: ({
          getValue,
        }: CellContext<
          PaginatedStatementTransactionDef,
          PaginatedStatementTransactionDef['order']
        >) => {
          const order = getValue();

          return (
            <Flex gap="1ch">
              <Typography text={order.type} />
              <StyledAnchor
                href={`${routePaths.statements}/${order.id}`}
                text={`#${order.id}`}
              />
            </Flex>
          );
        },
        header: 'Order',
        maxSize: 100,
        meta: {
          getIsClickable: getIsClickableIfValue,
        },
      },
      {
        accessorKey: 'statementId',
        cell: ({
          getValue,
        }: CellContext<
          PaginatedStatementTransactionDef,
          PaginatedStatementTransactionDef['statementId']
        >) => {
          const statementId = getValue();

          return (
            <StyledAnchor
              text={`#${statementId}`}
              to={`${routePaths.statements}/${statementId}`}
            />
          );
        },
        header: 'Statement',
        meta: {
          getIsClickable: getIsClickableIfValue,
        },
        size: 80,
      },
      {
        accessorKey: 'sku',
        cell: ({
          getValue,
        }: CellContext<
          PaginatedStatementTransactionDef,
          PaginatedStatementTransactionDef['sku']
        >) => {
          const sku = getValue();

          return (
            <Typography text={formatSkuForStatement(sku)} whiteSpace="nowrap" />
          );
        },
        header: 'SKU',
      },
      {
        accessorKey: 'quantity',
        cell: ({
          getValue,
        }: CellContext<
          PaginatedStatementTransactionDef,
          PaginatedStatementTransactionDef['quantity']
        >) => {
          const quantity = getValue();

          return <Typography text={formatStatementQuantity(quantity)} />;
        },
        header: 'Quantity',
        size: 40,
      },
      {
        accessorKey: 'pricePerItem',
        cell: ({
          getValue,
        }: CellContext<
          PaginatedStatementTransactionDef,
          PaginatedStatementTransactionDef['pricePerItem']
        >) => {
          const amountPerItem = getValue();

          return <Typography text={formatStatementCurrency(amountPerItem)} />;
        },
        header: 'Price per item',
        size: 60,
      },
      {
        accessorKey: 'total',
        cell: ({
          getValue,
        }: CellContext<
          PaginatedStatementTransactionDef,
          PaginatedStatementTransactionDef['total']
        >) => {
          const total = getValue();

          return <Typography text={formatStatementCurrency(total)} />;
        },
        header: 'Total',
        size: 60,
      },
    ];

    return columns;
  }, []);

  return columns;
};

export const Transactions = () => {
  const [filters, updateFilters] =
    useState<FetchPaginatedStatementTransactionsArgsDef>();

  const statementTransactionsColumns = useTransactionsColumns();

  const {
    filtersProps,
    pagingProps,
    records,
    searchProps,
    setRecords,
    setTotalCount,
    sortProps,
  } = useTableFilters<
    PaginatedStatementTransactionDef,
    FetchPaginatedStatementTransactionsArgsDef,
    TransactionsSortingField
  >({
    fetchRequest: updateFilters,
    filtersContent: <TransactionsFilters />,
    modalFiltersDetails: [
      {
        atom: transactionsPageAtoms.filters.dateRange,
        defaultValue: transactionsFiltersDefaults.filters.dateRange,
        payloadKey: 'dateRange',
      },
      {
        atom: transactionsPageAtoms.filters.designerName,
        defaultValue: transactionsFiltersDefaults.filters.designerName,
        payloadKey: 'designerName',
      },
      {
        atom: transactionsPageAtoms.filters.categories,
        defaultValue: transactionsFiltersDefaults.filters.categories,
        payloadKey: 'categories',
      },
      {
        atom: transactionsPageAtoms.filters.types,
        defaultValue: transactionsFiltersDefaults.filters.types,
        payloadKey: 'types',
      },
    ],
    pageName,
    pageSize: 100,
    sortByFilterDetails: {
      atom: transactionsPageAtoms.sortBy,
      defaultValue: transactionsFiltersDefaults.sortBy,
    },
    sortOptions: [
      { bidirectional: true, label: 'Date', sortingField: 'date' },
      { bidirectional: true, label: 'Quantity', sortingField: 'quantity' },
      { bidirectional: true, label: 'Total', sortingField: 'total' },
    ],
  });

  const { data, isLoading } = useGetPaginatedStatementTransactions({
    enabled: !!filters,
    fetchArgs: { ...filters, search: searchProps.value, ...sortProps.current },
  });

  const { Toast: DownloadCsvToast, downloadOption } = useGetDownloadMenuOption({
    columns: downloadColumns,
    fetchRequestWithFilters: (overrides) => {
      return fetchPaginatedStatementTransactions({
        ...filters,
        ...overrides,
      }).then(({ data }) => {
        return data;
      });
    },
    fileNamePrefix: 'Transactions Admin',
    totalResults: pagingProps.total,
  });

  const { MoreMenu } = useMoreMenu({
    options: [
      {
        ...downloadOption,
        allowedFor: [userRoles.admin, userRoles.lead],
      },
    ],
  });

  useEffect(() => {
    if (!isLoading) {
      setRecords(data?.data || []);
      setTotalCount(data?.pagination?.totalItems || 0);
    }
  }, [isLoading, data]);

  const pageTitle = 'Transactions';

  return (
    <PageWithTable pageTitle={pageTitle}>
      <PageWithTable.Header title={pageTitle}>
        <Protected allowedFor={[userRoles.admin, userRoles.lead]}>
          <MoreMenu />
          <DownloadCsvToast />
        </Protected>
      </PageWithTable.Header>
      <PageWithTable.Content>
        <Table
          columns={statementTransactionsColumns}
          data={records}
          filters={filtersProps}
          highlightHoveredRow
          paging={pagingProps}
          search={searchProps}
          sorting={sortProps}
        />
      </PageWithTable.Content>
    </PageWithTable>
  );
};

export const downloadColumns: DownloadColumn<PaginatedStatementTransactionDef>[] =
  [
    {
      getValue: ({ date }) => {
        return formatDate(date);
      },
      header: 'Date',
    },
    {
      getValue: ({ designerName }) => {
        return designerName;
      },
      header: 'Designer',
    },
    {
      getValue: ({ category }) => {
        return category;
      },
      header: 'Category',
    },
    {
      getValue: ({ type }) => {
        return type;
      },
      header: 'Type',
    },
    {
      getValue: ({ order }) => {
        return order?.id
          ? formatAsLinkForCSV({
              text: `${order.type} #${order.id}`,
              url: `${getAdminUrl() + routePaths.orders}/${order.id}`,
            })
          : '';
      },
      header: 'Order',
    },
    {
      getValue: ({ statementId }) => {
        return statementId
          ? formatAsLinkForCSV({
              text: `#${statementId}`,
              url: `${getAdminUrl() + routePaths.statements}/${statementId}`,
            })
          : '';
      },
      header: 'Order',
    },
    {
      getValue: ({ sku }) => {
        return formatSkuForStatement(sku);
      },
      header: 'SKU',
    },
    {
      getValue: ({ quantity }) => {
        return formatStatementQuantity(quantity);
      },
      header: 'Quantity',
    },
    {
      getValue: ({ pricePerItem: amountPerItem }) => {
        return formatStatementCurrency(amountPerItem);
      },
      header: 'Price per item',
    },
    {
      getValue: ({ total }) => {
        return formatStatementCurrency(total);
      },
      header: 'Total',
    },
  ];
