import { TableSortField } from '../../components/common/table/table.props';
import { printerTypes } from '../../constants/machines';
import { ModalFiltersDetail } from '../../components/common/table/hooks/use-table-filters/use-table-filters.types';
import {
  machinesFiltersDefaults,
  machinesPageAtoms,
} from '../../components/dropdowns/machines-filter/machines-filter.atoms';
import {
  FetchPaginatedMachinesArgs,
  MachinesSortingField,
} from '../../api/machines/routes/fetch-paginated-machines/fetch-paginated-machines.types';

export const dataAttrMachineId = 'data-machine-id';

export const machineTypeOptions = printerTypes.map((value) => {
  return {
    label: value,
    value,
  };
});

export const modalsFiltersDetails: ModalFiltersDetail<FetchPaginatedMachinesArgs>[] =
  [
    {
      atom: machinesPageAtoms.filters.attentionOnly,
      defaultValue: machinesFiltersDefaults.filters.attentionOnly,
      payloadKey: 'attentionOnly',
    },
    {
      atom: machinesPageAtoms.filters.difficulty,
      defaultValue: machinesFiltersDefaults.filters.difficulty,
      payloadKey: 'difficulty',
    },
    {
      atom: machinesPageAtoms.filters.locations,
      defaultValue: machinesFiltersDefaults.filters.locations,
      payloadKey: 'locations',
    },
    {
      atom: machinesPageAtoms.filters.machineIssues,
      defaultValue: machinesFiltersDefaults.filters.machineIssues,
      payloadKey: 'machineIssues',
    },
    {
      atom: machinesPageAtoms.filters.statuses,
      defaultValue: machinesFiltersDefaults.filters.statuses,
      payloadKey: 'statuses',
    },
    {
      atom: machinesPageAtoms.filters.types,
      defaultValue: machinesFiltersDefaults.filters.types,
      payloadKey: 'types',
    },
    {
      atom: machinesPageAtoms.filters.materials,
      defaultValue: machinesFiltersDefaults.filters.materials,
      payloadKey: 'materials',
    },
  ];

export const sortOptions: TableSortField<MachinesSortingField>[] = [
  { bidirectional: true, label: 'Name', sortingField: 'name' },
  {
    bidirectional: true,
    label: 'Last Status Change',
    sortingField: 'lastStatusChangeDate',
  },
  { bidirectional: true, label: 'Type', sortingField: 'type' },
  { bidirectional: true, label: 'Status', sortingField: 'status' },
  {
    bidirectional: true,
    label: 'Maintenance in',
    sortingField: 'maintenanceIn',
  },
  {
    bidirectional: true,
    label: 'Open issues',
    sortingField: 'openIssuesCount',
  },
];

export const refreshPageMessage =
  'One of the selected item(s) has been updated. Please refresh the page.';
