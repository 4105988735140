import { getFileUrl } from 'gantri-components';
import { format } from 'date-fns';
import { StatementDef } from '../../api/statements/routes';
import { cloudinaryUrl } from '../../helpers/images';
import { MEDIUM_FORMAT } from '../../constants/dates';

export const formattedDesignerLocation = (payout: StatementDef): string => {
  const { city, country, state } = payout.designer.location;

  return [city, state || country].filter(Boolean).join(', ');
};

export const getLogoUrl = (statement: StatementDef): string => {
  const logoUrl = getFileUrl({
    directory: 'designers',
    fileName: statement?.designer?.logoUrl,
    fileType: 'headshot',
    identifiers: {
      userId: statement?.designer?.userId,
    },
  });

  return cloudinaryUrl(logoUrl, {
    crop: 'limit',
    height: 80,
    width: 80,
  });
};

export const formattedSummaryPeriod = (payout: StatementDef) => {
  const formattedStartDate = format(new Date(payout.startDate), MEDIUM_FORMAT);
  const formattedEndDate = format(new Date(payout.endDate), MEDIUM_FORMAT);

  return `${formattedStartDate} - ${formattedEndDate}`;
};
