import { FC, useState } from 'react';
import { useRecoilState } from 'recoil';
import { SearchField } from 'gantri-components';
import { debounce } from 'lodash';
import { FilterProps } from '../../../../components/dropdowns/index';
import DatePicker from '../../../../components/common/date-picker';
import { transactionsPageAtoms } from '../../transactions.atoms';
import { OnDateChanged } from '../../../../components/common/date-picker/date-picker.types';
import { FilterResetLabel } from '../../../../components/common/filter-reset-label';
import { AtomsCheckboxList } from '../../../../components/common/atoms-checkbox-list';
import {
  FilterGrid,
  FilterRow,
} from '../../../../components/common/filter-grid';
import { statementCategory } from '../../../../api/statements/routes';
import { transactionTypesMap } from '../../../../constants/options';
import { useFetchAllDesigners } from '../../../../api/designers/routes';

export const TransactionsFilters: FC<FilterProps> = () => {
  const [designerSearchValue, setDesignerSearchValue] = useState<string>('');

  const [dateRange, setDateRange] = useRecoilState(
    transactionsPageAtoms.filters.dateRange,
  );
  const [designerName, setDesignerName] = useRecoilState(
    transactionsPageAtoms.filters.designerName,
  );

  const onDateChange: OnDateChanged = ({ formattedFrom, formattedTo }) => {
    setDateRange({
      from: formattedFrom || null,
      to: formattedTo || null,
    });
  };

  const transactionsCategoryFilterItems = Object.values(statementCategory);
  const transactionsTypeFilterItems = [
    transactionTypesMap.order,
    transactionTypesMap.thirdParty,
    transactionTypesMap.trade,
    transactionTypesMap.wholesale,
  ];

  const { data, isLoading } = useFetchAllDesigners();

  const designers = data?.designers || [];

  return (
    <FilterGrid>
      <FilterRow>
        <FilterResetLabel
          atom={transactionsPageAtoms.filters.dateRange}
          default={transactionsPageAtoms.defaults.filters.dateRange}
          text="Date range"
        />
        <DatePicker
          allowFutureDays
          initialValue={dateRange}
          range
          resetDates={transactionsPageAtoms.defaults.filters.dateRange}
          onDateChanged={onDateChange}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={transactionsPageAtoms.filters.designerName}
          default={transactionsPageAtoms.defaults.filters.designerName}
          text="Designer"
        />

        <SearchField
          idProperty="id"
          items={designers}
          labelProperty="name"
          placeholder="Search designer"
          processing={isLoading}
          searchable
          value={designerName || designerSearchValue}
          onSelect={(item) => {
            setDesignerName(item?.name);
          }}
          onTextChange={(searchValue) => {
            debounce(() => {
              setDesignerSearchValue(searchValue);
              setDesignerName(null);
            }, 300);
          }}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={transactionsPageAtoms.filters.categories}
          default={transactionsPageAtoms.defaults.filters.categories}
          text="Category"
        />
        <AtomsCheckboxList
          atom={transactionsPageAtoms.filters.categories}
          gridProps={{ columns: 2 }}
          items={transactionsCategoryFilterItems}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={transactionsPageAtoms.filters.types}
          default={transactionsPageAtoms.defaults.filters.types}
          text="Type"
        />
        <AtomsCheckboxList
          atom={transactionsPageAtoms.filters.types}
          gridProps={{ columns: 2 }}
          items={transactionsTypeFilterItems}
        />
      </FilterRow>
    </FilterGrid>
  );
};
