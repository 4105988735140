import { jobSteps, jobTypeOptions } from './options';

export const machineTypes = {
  creality: 'Ender 5+',
  p1p: 'P1P',
  tumbler: 'Tumbler',
  ultimaker: 'Ultimaker',
} as const;

export const bambuMachineTypes: MachineType[] = [machineTypes.p1p];

export const machineIssueSources = {
  manual: 'Manual',
  print: jobSteps.print,
  printQc: `${jobSteps.print} ${jobTypeOptions.qc}`,
} as const;

export type MachineType = typeof machineTypes[keyof typeof machineTypes];

export const printerTypes: MachineType[] = Object.values(machineTypes);

const removeInactiveMachineTypes = (machineTypesToUpdate: MachineType[]) => {
  const inactiveMachineTypes = [machineTypes.tumbler] satisfies MachineType[];

  return machineTypesToUpdate.filter((type) => {
    return !inactiveMachineTypes.some((inactiveType) => {
      return inactiveType === type;
    });
  });
};

export const newPartModalMachineTypes = [
  machineTypes.p1p,
  machineTypes.creality,
];

export const machineStatuses = {
  archived: 'Archived',
  inProgress: 'In progress',
  manual: 'Manual',
  offline: 'Offline',
  ready: 'Ready',
} as const;

export type MachineStatus =
  typeof machineStatuses[keyof typeof machineStatuses];
