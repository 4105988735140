import {
  Button,
  Cell,
  Conditional,
  Flex,
  FormikInput,
  Grid,
  Icon,
  Modal,
  Radio,
  SearchField,
  Stack,
  Tooltip,
  Typography,
} from 'gantri-components';
import { Form, Formik, FormikProps } from 'formik';
import React from 'react';
import { ChangeSpoolModalProps } from './change-spool-modal.types';
import { ChangeSpoolFormDef, ChangeSpoolSchema } from './change-spool.schema';
import {
  inventoryTransactionSpoolDetails,
  materialsFilterOptions,
} from '../../../../constants/parts';
import { StyledStateBox } from './change-spool.styles';
import { ChangeSpoolMachinesTooltip } from './change-spool-machines-tooltip';
import { useChangeMachineSpool } from '../../../../api/machines/routes/change-spool';

const initialValues: ChangeSpoolFormDef = {
  material: null,
  state: 'used', // new or used spool
  weight: null,
};

export const ChangeSpoolModal = (props: ChangeSpoolModalProps) => {
  const { isManagementFleet, machinesToUpdate, onClose, onSuccess } = props;

  const { isLoading, onChangeSpool } = useChangeMachineSpool({
    onSuccess: async () => {
      onClose?.();
      await onSuccess?.();
    },
  });

  const onConfirm = async (values) => {
    await onChangeSpool({
      addedType: values.state,
      machineIds: machinesToUpdate.map(({ id }) => {
        return id;
      }),
      material: values.material,
      weight: values.weight,
    });
  };

  const materialChoices = materialsFilterOptions.map((value) => {
    return {
      label: value,
      value,
    };
  });

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validateOnMount
      validationSchema={ChangeSpoolSchema}
      onSubmit={onConfirm}
    >
      {({ isValid, values }: FormikProps<any>) => {
        const isNew = values.state === 'new';
        const details = inventoryTransactionSpoolDetails[values.material];

        return (
          <Form>
            <Modal
              footer={
                <>
                  <Button
                    size="large"
                    text="Cancel"
                    variant="secondary"
                    onClick={onClose}
                  />
                  <Button
                    disabled={!isValid || isLoading}
                    processing={isLoading}
                    size="large"
                    text="Confirm Change"
                    type="submit"
                  />
                </>
              }
              header="Change Spool"
              width={{ lg: '51rem', sm: '100%' }}
              onClose={onClose}
            >
              <Stack gap="3x">
                <Conditional condition={machinesToUpdate.length > 1}>
                  <Flex alignItems="center" gap=".5x">
                    <Typography
                      htmlText={`Provide spool change details for: <strong>${machinesToUpdate.length} machines</strong>.`}
                    />

                    <Tooltip
                      Component={
                        <ChangeSpoolMachinesTooltip
                          machinesToUpdate={machinesToUpdate}
                        />
                      }
                    >
                      <Button
                        icon={<Icon name="ui-control:lines_three_24" />}
                        iconLocation="right"
                        text="View list"
                        variant="ghost"
                      />
                    </Tooltip>
                  </Flex>
                </Conditional>

                <FormikInput
                  Field={
                    <SearchField
                      items={materialChoices}
                      labelPosition="top"
                      labelText="Spool material"
                      maxHeight={400}
                      placeholder="Select material"
                    />
                  }
                  name="material"
                  required
                />

                <Conditional
                  condition={!isManagementFleet}
                  Fallback={
                    <FormikInput
                      name="weight"
                      required
                      rightIcon={
                        <Typography color="t2" text="g" variant="p2" />
                      }
                      type="number"
                    />
                  }
                >
                  <Cell rowGap="x">
                    <Typography
                      color="t1"
                      text="Used or new spool(s) added?"
                      variant="h6"
                    />

                    <Grid columns={2} gap="x">
                      <StyledStateBox $active={values.state === 'used'}>
                        <FormikInput
                          Field={
                            <Radio
                              groupValue="used"
                              labelHtmlText="<strong>Used</strong> spool(s) added"
                            />
                          }
                          name="state"
                        />
                      </StyledStateBox>

                      <StyledStateBox $active={values.state === 'new'}>
                        <FormikInput
                          Field={
                            <Radio
                              groupValue="new"
                              labelHtmlText="<strong>New</strong> spool(s) added"
                            />
                          }
                          name="state"
                        />
                      </StyledStateBox>
                    </Grid>
                  </Cell>
                </Conditional>

                <Conditional
                  condition={(isNew || isManagementFleet) && !!details}
                >
                  <Cell>
                    <Typography
                      color="t1"
                      paddingBottom=".5x"
                      text="Inventory Transaction Details"
                      variant="h6"
                    />

                    <Typography
                      text={`A transaction of ${
                        details?.weight * machinesToUpdate.length
                      }kg of inventory will be recorded.`}
                    />

                    <Typography
                      color="t2"
                      text={`${details?.id}: ${details?.name}`}
                    />
                  </Cell>
                </Conditional>
              </Stack>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};
