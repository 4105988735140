import { FC } from 'react';
import { Conditional, Grid, Typography } from 'gantri-components';
import { ChangeSpoolMachinesTooltipProps } from './change-spool-modal.types';
import { StyledTypography } from './change-spool.styles';

const limit = 20;

export const ChangeSpoolMachinesTooltip: FC<ChangeSpoolMachinesTooltipProps> = (
  props,
) => {
  const { machinesToUpdate } = props;
  const hasMoreThanMaxMachines = machinesToUpdate.length > limit;

  return (
    <Grid
      gap="x"
      horizontalPadding="2x"
      maxHeight="28rem"
      overflow="auto"
      verticalPadding="x"
    >
      {machinesToUpdate.slice(0, limit).map((machine) => {
        return (
          <StyledTypography
            key={machine.id}
            htmlText={`${machine.name} <span>${machine.bay}</span>`}
            verticalPadding="x"
          />
        );
      })}

      <Conditional condition={hasMoreThanMaxMachines}>
        <Typography
          color="t2"
          text={`+${machinesToUpdate.length - limit} machines`}
        />
      </Conditional>
    </Grid>
  );
};
