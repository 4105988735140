import { atom, RecoilState } from 'recoil';
import {
  InventoryRequest,
  Machine,
} from '../../../../../../../api/jobs/jobs.types';
import {
  SpoolChanged,
  StartPrintPrintJobModalStep,
} from './start-print-print-job-modal.types';
import { Material } from '../../../../../../../constants/parts';

export const atomKeyPrefix = 'START_PRINT_PRINT_JOB_MODAL';

export const startPrintPrintJobModalAtoms = {
  assignedMachine: atom<number>({
    default: null,
    key: `${atomKeyPrefix}-assigned-machine`,
  }),
  assignedMachineInstance: atom<Machine>({
    default: null,
    key: `${atomKeyPrefix}-assigned-machine-instance`,
  }),
  buildPlateChanged: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-build-plate-changed`,
  }),
  buildPlateNeedsChanged: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-build-plate-needs-changed`,
  }),
  inventoryRequests: atom<InventoryRequest[]>({
    default: [],
    key: `${atomKeyPrefix}-inventory-request`,
  }),
  machines: atom<unknown[]>({
    default: [],
    key: `${atomKeyPrefix}-machines`,
  }),
  materialChanged: atom<Material>({
    default: null,
    key: `${atomKeyPrefix}-material-changed`,
  }),
  spoolChanged: atom<SpoolChanged>({
    default: 'NO_CHANGE',
    key: `${atomKeyPrefix}-spool-changed`,
  }),
  step: atom<StartPrintPrintJobModalStep>({
    default: 'ASSIGN_MACHINE',
    key: `${atomKeyPrefix}-step`,
  }),
  updateOnClose: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-update-on-close`,
  }),
} satisfies Record<string, RecoilState<any>>;
