import { Box, Conditional, Icon, Stack, Typography } from 'gantri-components';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useMemo } from 'react';
import { RadioButton } from '../../../../../../../../../../../../components/common/radio-button';
import { startPrintPrintJobModalAtoms } from '../../../../../start-print-print-job-modal.atoms';
import {
  spoolInventoryDetails,
  spoolInventoryNeedValues,
} from './new-spool-inventory.constants';
import { getInventoryRequestsForInventory } from './helpers/get-inventory-requests-for-inventory';
import { NewSpoolInventoryPropsDef } from './new-spool-inventory.types';
import { Inventory } from '../../../../../../../../../../../../api/inventories/routes/fetch-all/fetch-all-inventories.types';
import { isOpaqueMaterial } from '../../../../../../../../../../../../constants/parts';

export const NewSpoolInventory = (props: NewSpoolInventoryPropsDef) => {
  const { isLoading, material, materialInventories } = props;
  const isOpaque = isOpaqueMaterial(material);
  const setInventoryRequests = useSetRecoilState(
    startPrintPrintJobModalAtoms.inventoryRequests,
  );
  const [materialChanged, setMaterialChanged] = useRecoilState(
    startPrintPrintJobModalAtoms.materialChanged,
  );

  const inventoryItems = useMemo(() => {
    if (materialInventories) {
      // ? indexed in display order
      const inventoryIdsToShow = isOpaque
        ? [
            spoolInventoryDetails.opaqueSandable.id,
            spoolInventoryDetails.opaqueOldFormulation.id,
          ]
        : [
            spoolInventoryDetails.translucentPolymaker.id,
            spoolInventoryDetails.translucentOldFormulation.id,
          ];

      return inventoryIdsToShow.map((idToShow) => {
        const matchingInventory = materialInventories.find(({ id }) => {
          return id === idToShow;
        });

        const { description, material } = Object.values(
          spoolInventoryDetails,
        ).find(({ id }) => {
          return id === idToShow;
        });

        const updatedInventory: Inventory = {
          ...matchingInventory,
          description,
          material,
        };

        return updatedInventory;
      });
    }

    return [];
  }, [materialInventories]);

  return (
    <Stack gap=".5x">
      <Typography text="Select material added:" variant="h6" />

      <Stack gap="x">
        <Conditional
          key="is-loading-inventories"
          condition={isLoading}
          Fallback={
            <>
              <Typography
                text={`A transaction of ${
                  isOpaque
                    ? spoolInventoryNeedValues.opaque
                    : spoolInventoryNeedValues.translucent
                }kg of inventory will be recorded. Select material:`}
              />

              {inventoryItems.map((inventory, index) => {
                const {
                  description,
                  id,
                  material: inventoryMaterial,
                  name,
                } = inventory;

                const groupValue = String(id);

                const isChecked = inventory.material === materialChanged;

                // ? The inventory request only ever has one index
                const selectedInventoryId =
                  inventory.material === materialChanged ? inventory.id : null;

                const value = String(selectedInventoryId || '');

                return (
                  <RadioButton
                    key={groupValue}
                    buttonVariant="radio"
                    checked={isChecked}
                    groupValue={groupValue}
                    name={groupValue}
                    value={value}
                    onSelected={() => {
                      const inventoryRequests =
                        getInventoryRequestsForInventory(inventory);

                      setInventoryRequests(inventoryRequests);

                      setMaterialChanged(inventoryMaterial);
                    }}
                  >
                    <Box>
                      <Typography display="inline" text={description} />
                      &nbsp;
                      <Typography
                        color="t2"
                        display="inline"
                        text={`- #${id}: ${name}`}
                      />
                    </Box>
                  </RadioButton>
                );
              })}
            </>
          }
        >
          <Icon color="link" name="animated:loader" size="2.4rem" />
        </Conditional>
      </Stack>
    </Stack>
  );
};
